<template>
  <div class="index-container">
    <div class="calendar">
      <div class="schedule">
        <checkSchedule ref="checkSchedule" @select="dateSelect" />
        <div
          style="
            display: flex;
            justify-items: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            margin-top: 15px;
          "
        >
          <el-checkbox-group v-model="checkTypes">
            <el-checkbox
              v-for="checkType in $config.checkType"
              :label="checkType.value"
              :key="checkType.key"
              >{{ checkType.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="setting">
        <scheduleTimes ref="scheduleTimes" :step="true" @next="handleNext" />
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center">
      <el-button class="submit-btn" type="warning" @click="handlePreStep">
        上一步
      </el-button>
    </div>
  </div>
</template>
  <script>
import checkSchedule from "@/views/check/components/checkSchedule.vue";
import scheduleTimes from "@/views/check/components/scheduleTimes.vue";
export default {
  components: { checkSchedule, scheduleTimes },
  data() {
    return {
      checkTypes: [],
    };
  },
  methods: {
    dateSelect(v) {
      this.$nextTick(() => {
        this.$refs.scheduleTimes.loadTimes(v.day, v.times);
      });
    },
    handleNext(v) {
      if (this.checkTypes.length == 0) {
        this.msg.errorMsg("请选择测试项目");
        return;
      }
      let checkTypeValue = 0;
      this.checkTypes.forEach((r) => {
        checkTypeValue = r | checkTypeValue;
      });
      v.CheckTypes = checkTypeValue;
      this.$emit("next", v);
    },
    handlePreStep() {
      this.$emit("prev", "");
    },
  },
  mounted() {},
};
</script>
  <style lang="scss" scoped>
.index-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  .calendar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .schedule {
      width: 65%;
    }
    .setting {
      flex: 1;
      align-content: flex-start;
    }
  }
  .dataList {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>